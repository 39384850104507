const messagesValidation = {
  // Slovene
  // NOTE: all prefixed `[sl]` values have not been translated (probably not used at the time).
  sl: {
    missingValue: {
      checkbox: 'To polje je obvezno',
      radio: 'To polje je obvezno',
      select: 'To polje je obvezno',
      'select-multiple': 'Izberite vsaj eno od možnosti.',
      default: 'To polje je obvezno',
    },
    patternMismatch: {
      email: 'E-naslov ni v pravilni obliki.',
      url: 'Vnesite spletni naslov.',
      number: 'Vnesite samo številke.',
      color: '[sl] Please match the following format: #rrggbb',
      date: '[sl] Please use the YYYY-MM-DD format',
      time: '[sl] Please use the 24-hour time format. Ex. 23:00',
      month: '[sl] Please use the YYYY-MM format',
      default: 'Napačna oblika.',
    },
    outOfRange: {
      over: 'Izberite vrednost, ki ni večja od {max}.',
      under: 'Izberite vrednost, ki ni manjša od {min}.',
    },
    wrongLength: {
      over: 'Skrajšajte besedilo na največ {maxLength} znakov. Trenutno uporabljate {length} znakov.',
      under: 'Podaljšajte besedilo na najmanj {minLength} znakov. Trenutno uporabljate {length} znakov.',
    },
    checkboxGroup: 'Izberite vsaj eno od možnosti',
  },
  // English
  en: {
    missingValue: {
      checkbox: 'This field is required.',
      radio: 'Please select a value.',
      select: 'Please select a value.',
      'select-multiple': 'Please select at least one value.',
      default: 'Please fill out this field.',
    },
    patternMismatch: {
      email: 'Please enter a valid email address.',
      url: 'Please enter a URL.',
      number: 'Please enter a number',
      color: 'Please match the following format: #rrggbb',
      date: 'Please use the YYYY-MM-DD format',
      time: 'Please use the 24-hour time format. Ex. 23:00',
      month: 'Please use the YYYY-MM format',
      default: 'Please match the requested format.',
    },
    outOfRange: {
      over: 'Please select a value that is no more than {max}.',
      under: 'Please select a value that is no less than {min}.',
    },
    wrongLength: {
      over: 'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters.',
      under: 'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters.',
    },
    checkboxGroup: 'Please select at least one option',
  },
};

export default messagesValidation;
