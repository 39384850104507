import Modal from './modal';

export default function modalInit() {
  const modalOpenTriggers = Array.from(document.querySelectorAll(['[data-modal-trigger]']));

  modalOpenTriggers.forEach((trigger) => {
    // Creating instance of modal
    const name = trigger.dataset.modalTrigger;
    const modal = new Modal({
      selector: `[data-modal="${name}"]`,
    });

    // Adding event listener for trigger
    trigger.addEventListener('click', () => {
      modal.open();
    });
  });
}
