/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import Swiper, { Navigation, Pagination, A11y, Keyboard } from 'swiper';
import 'swiper/css/bundle';

Swiper.use([Navigation, Pagination, A11y, Keyboard]);

export default function sliderInit() {
  const swiperSlider = new Swiper('.js-swiper', {
    updateOnWindowResize: true,
    watchOverflow: true,
    slidesPerView: 1.2,
    spaceBetween: 16,
    a11y: {
      enabled: true,
      prevSlideMessage: 'Nazaj',
      nextSlideMessage: 'Naprej',
      paginationBulletMessage: 'Na {{index}}',
    },
    keyboard: {
      enabled: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      460: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 2,
      },
      1023: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  });
}
