/* eslint-disable no-unused-vars */
// https://github.com/bkkr-team/bkkr-choices

import Choices from 'choices.js';

const options = {
  placeholder: false,
  searchEnabled: false,
  shouldSort: false,
  noResultsText: 'No results found',
  noChoicesText: 'No choices to choose from',
  itemSelectText: '',
};

export default function selectInit() {
  const selects = Array.from(document.querySelectorAll('.js-select'));
  selects.forEach((select) => {
    const singleSelect = new Choices(select, options);
  });
}
