// NOTE: change content according to form purpose.

const submitMessages = {
  // Slovene
  sl: {
    success: 'Vaše sporočilo je bilo uspešno oddano.',
    errorGeneral: 'Prišlo je do napake, poskusite še enkrat.',
  },
  // English
  en: {
    success: 'Thank you for subscribing!',
    errorGeneral: 'An error has occurred, please try again later.',
  },
};

export default submitMessages;
