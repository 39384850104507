/* global gtag */
// NOTE: see more for config options at https://github.com/cferdinandi/bouncer

import Bouncer from 'formbouncerjs';
import messagesValidation from './messages/validation';
import messagesSubmit from './messages/submit';

// Set language
const { lang } = document.documentElement;

// Prepopulate submit messages
const messageSuccess = document.querySelector('.js-form-success');
const messageErrorGeneral = document.querySelector('.js-form-error-general');
const submit = document.querySelector('.js-form-submit');
const form = document.querySelector('.js-form');

const toggleSubmitMessages = (elementToShow) => {
  // Hide/reset all messages
  messageSuccess.classList.add('hidden');
  messageErrorGeneral.classList.add('hidden');

  if (elementToShow === 'success') {
    // form.classList.add('hidden');
    form.reset();
    submit.disabled = false;
    messageSuccess.classList.remove('hidden');
    submit.classList.remove('-loading');
  } else if (elementToShow === 'errorGeneral') {
    messageErrorGeneral.classList.remove('hidden');
  }
};

const setSubmitting = (condition) => {
  if (condition) {
    // Disable submit button, show loading
    submit.disabled = true;
    submit.classList.add('-loading');
  } else if (!condition) {
    // Enable submit button, hide loading
    submit.disabled = false;
    submit.classList.remove('-loading');
  }
};

export default function formContact() {
  if (form) {
    messageSuccess.innerHTML = messagesSubmit[lang].success;
    messageErrorGeneral.innerHTML = messagesSubmit[lang].errorGeneral;

    // eslint-disable-next-line no-unused-vars
    const bouncer = new Bouncer('.js-form', {
      disableSubmit: true,
      fieldClass: 'error', // NOTE: plugin doesn't let us use empty value, so this is used just as a placeholder and is not anyhow connected with our input macro
      errorClass: 'form-item__error',
      customValidations: {
        // eslint-disable-next-line consistent-return
        checkboxGroup(field) {
          if (field.getAttribute('name') === 'interest') {
            if (
              document.getElementById('property-insurance').checked ||
              document.getElementById('life-insurance').checked ||
              document.getElementById('health-insurance').checked ||
              document.getElementById('travel-insurance').checked ||
              document.getElementById('retirement-insurance').checked ||
              document.getElementById('wealth-insurance').checked
            ) {
              return false;
            }
            return true;
          }
        },
      },
      messages: {
        ...messagesValidation[lang],
      },
    });

    // Listener for form valid
    document.addEventListener(
      'bouncerFormValid',
      () => {
        // Gathering post data
        const postData = {};
        const inputs = Array.from(document.querySelectorAll('.js-form input'));
        const formName = form.getAttribute('name');

        // Input fields
        inputs.forEach((input) => {
          if (input.type === 'checkbox') {
            if (input.checked) {
              postData[input.id] = 'da';
            } else {
              postData[input.id] = 'ne';
            }
          } else {
            postData[input.id] = input.value;
          }
        });

        const payload = {
          // from: postData.email,
          subject: `${postData.firstname} ${postData.lastname} - preko forme na dobriljudje.si`,
          html: `
          <p>Ime: <strong>${postData.firstname}</strong></p>
          <p>Priimek: <strong>${postData.lastname}</strong></p>
          <p>Poštna številka: <strong>${postData.zip}</strong></p>
          <p>Kraj: <strong>${postData.town}</strong></p>
          <p>E-naslov: <strong>${postData.email}</strong></p>
          <p>Telefon: <strong>${postData.phone}</strong></p>
          <p><strong>Zanimanje:</strong></p>
          <ul>
          <li><p>Premoženjska zavarovanja / avto, dom: <strong>${postData['property-insurance']}</strong></p></li>
          <li><p>Življenjska zavarovanja / za primer smrti, naložbeno zavarovanje: <strong>${postData['life-insurance']}</strong></p></li>
          <li><p>Dodatno zdravstveno zavarovanje: <strong>${postData['health-insurance']}</strong></p></li>
          <li><p>Zavarovanja za tujino: <strong>${postData['travel-insurance']}</strong></p></li>
          <li><p>Pokojninsko zavarovanje in rente: <strong>${postData['retirement-insurance']}</strong></p></li>
          <li><p>Varčevanje in naložbe v vzajemnih skladih / finančne naložbe: <strong>${postData['wealth-insurance']}</strong></li></p></ul>`,
        };

        // Posting data
        setSubmitting(true);

        fetch('/functions/smtp', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 200) {
              // handle success
              if (typeof window.gtag === 'function') {
                // If cookies were accepted
                gtag('event', 'generate_lead', {
                  event_label: formName,
                  event_category: 'engagement',
                });
              }

              toggleSubmitMessages('success');
            } else {
              toggleSubmitMessages('errorGeneral');
            }
            setSubmitting(false);
          })
          .catch((e) => {
            toggleSubmitMessages('errorGeneral');
            setSubmitting(false);
            // eslint-disable-next-line no-console
            console.error(e);
          });
      },
      false,
    );

    // Listener for show error
    document.addEventListener(
      'bouncerShowError',
      (event) => {
        // Adding error class name to form item
        event.target.closest('.form-item').classList.add('-has-error');
      },
      false,
    );

    // Listener for remove error
    document.addEventListener(
      'bouncerRemoveError',
      (event) => {
        if (event.target.getAttribute('name') === 'interest') {
          const formGroup = event.target.parentNode.parentNode;
          const formItems = Array.from(formGroup.querySelectorAll('.form-item'));
          formItems.forEach((formItem) => {
            formItem.classList.remove('-has-error');
            const input = formItem.querySelector('input');
            input.classList.remove('error');
            input.removeAttribute('aria-describedby');
            input.removeAttribute('aria-invalid');
          });
        } else {
          // Removing error class name from form item
          event.target.closest('.form-item').classList.remove('-has-error');
        }
      },
      false,
    );
  }
}
