/* eslint-disable no-unused-vars */
import colorListInit from '../../../utils/colors-list';
import selectInit from '../../components/form/select/select';
import accordionInit from '../../components/accordion/accordion';
import sliderInit from '../../components/slider/slider';
import modalInit from '../../components/modal/modalInit';
import formInit from './form';
import CookieConsent from '../../components/cookie-consent/cookie-consent';

// Cookie consent
async function cookies() {
  const cookieOptions = await import(`../../components/cookie-consent/cookie-options-${document.documentElement.lang}.json`);
  const consent = new CookieConsent(cookieOptions);
}

colorListInit();
selectInit();
accordionInit();
sliderInit();
modalInit();
formInit();
cookies();

if (document.querySelector('.hero__cta--arrow')) {
  const buttonNext = document.querySelector('.hero__cta--arrow');
  const sectionnNext = document.querySelector('#spoznajte');

  buttonNext.addEventListener('click', (event) => {
    event.preventDefault();
    window.scrollTo({
      top: sectionnNext.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  });
}
