/* eslint class-methods-use-this: 0 */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class CookieConsent {
  constructor(options) {
    this.options = this.validate(options);
    this.cookieGroups = this.options.cookieGroups;
    this.texts = this.options.texts;
    this.content = this.options.content;
    this.cookieGroupsParsed = false;
    this.cookies = {
      showOnInit: true,
      groupConsent: {},
    };
    this.init();
  }

  init() {
    this.createHtmlElements();
    this.referenceHtmlElements();
    this.addEventListeners();
    this.checkCookies();
  }

  createHtmlElements() {
    const bodyRef = document.querySelector('body');
    const cookieConsentTemplate = this.createMainTemplate(this.content);
    bodyRef.appendChild(cookieConsentTemplate);
  }

  referenceHtmlElements() {
    this.consent = document.querySelector('.rs-cc');
    this.consentMain = document.querySelector('.rs-cc--main');
    this.step1 = document.querySelector('.rs-cc__step-1');
    this.step2 = document.querySelector('.rs-cc__step-2');
    this.btnShowConsent = document.querySelectorAll('.rs-cc__open');
    this.btnsCloseConsent = Array.from(document.querySelectorAll('.rs-cc__close'));
    this.btnConfirmAll = document.querySelector('.rs-cc__confirm-all');
    this.btnOpenSettings = document.querySelector('.rs-cc__open-settings');
    this.btnSaveSettings = document.querySelector('.rs-cc__save-settings');
  }

  addEventListeners() {
    if (this.btnConfirmAll) {
      this.btnConfirmAll.addEventListener('click', () => this.confirmAll());
    }
    if (this.btnOpenSettings) {
      this.btnOpenSettings.addEventListener('click', () => this.openSettings());
    }
    if (this.btnSaveSettings) {
      this.btnSaveSettings.addEventListener('click', () => this.saveSettings());
    }

    this.btnShowConsent.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        this.hide(this.step1);
        if (!this.step2) {
          this.openSettings();
        } else {
          this.parseCookieGroups();
          this.show(this.step2);
          this.show(this.consent);
          disableBodyScroll(this.consentMain);
        }
      });
    });

    if (this.btnsCloseConsent) {
      this.btnsCloseConsent.forEach((btn) => {
        btn.addEventListener('click', () => {
          this.hide(this.step1);
          this.hide(this.step2);
          this.hide(this.consent);
          enableBodyScroll(this.consentMain);
        });
      });
    }
  }

  checkCookies() {
    const cookies = this.getCookie('CookieConsent');
    this.hide(this.consent);
    enableBodyScroll(this.consentMain);

    if (!cookies) {
      this.show(this.step1);
      this.show(this.consent);
      disableBodyScroll(this.consentMain);

      this.setCookie('CookieConsent', JSON.stringify(this.cookies), 365);
    } else {
      this.cookies = JSON.parse(cookies);

      if (this.cookies.showOnInit) {
        this.show(this.step1);
        this.show(this.consent);
        disableBodyScroll(this.consentMain);
      }

      this.cookieGroups.forEach((group) => {
        if (group.id && this.cookies.groupConsent[group.id] === true) {
          this.enableScriptGroup(group.id);
        }
      });
    }
  }

  parseCookieGroups() {
    if (this.cookieGroupsParsed) {
      return;
    }

    const cookieGroupsRef = document.querySelector('.rs-cc__cookie-groups');

    this.cookieGroups.forEach((group, i) => {
      const cookieGroupTemplate = this.createCookieGroup(group);
      cookieGroupsRef.appendChild(cookieGroupTemplate);

      this.parseCookieTables(group.cookies, i);
      this.toggleCookieTables(i);

      this.setCookies(group, i);
    });

    this.cookieGroupsParsed = true;
  }

  parseCookieTables(cookies, i) {
    const cookieTableRef = document.querySelectorAll('.rs-cc__group-table')[i];

    cookies.forEach((cookie) => {
      const cookieTableTemplate = this.createCookieTable(cookie);
      cookieTableRef.appendChild(cookieTableTemplate);
    });
  }

  toggleCookieTables(i) {
    const table = document.querySelectorAll('.rs-cc__table-container')[i];
    const btnShowList = document.querySelectorAll('.rs-cc__show-list')[i];
    const btnHideList = document.querySelectorAll('.rs-cc__hide-list')[i];

    btnShowList.addEventListener('click', () => {
      this.show(table);
      this.show(btnHideList);
      this.hide(btnShowList);
    });

    btnHideList.addEventListener('click', () => {
      this.hide(table);
      this.show(btnShowList);
      this.hide(btnHideList);
    });
  }

  setCookies(group, i) {
    const checkbox = document.querySelectorAll('.rs-cc__checkbox')[i];

    if (group.necessary) {
      checkbox.checked = true;
      checkbox.disabled = true;
    }

    if (this.cookies.groupConsent[group.id]) {
      checkbox.checked = true;
    }

    checkbox.addEventListener('change', () => {
      if (checkbox.checked) {
        this.cookies.groupConsent[group.id] = true;
      } else {
        this.cookies.groupConsent[group.id] = false;
      }
    });
  }

  unsetCookies() {
    this.cookieGroups.forEach((group) => {
      if (this.cookies.groupConsent[group.id] === false) {
        group.cookies.forEach((cookie) => this.unsetCookie(cookie.title));
      }
    });
  }

  confirmAll() {
    const scripts = Array.from(document.querySelectorAll('.rs-cc__script'));
    scripts.forEach((el) => this.enableScript(el));

    this.hide(this.step1);
    this.hide(this.consent);
    enableBodyScroll(this.consentMain);
    this.cookies.showOnInit = false;
    this.cookieGroups.forEach((group) => {
      this.cookies.groupConsent[group.id] = true;
    });
    this.setCookie('CookieConsent', JSON.stringify(this.cookies), 365);
  }

  openSettings() {
    this.parseCookieGroups();
    this.show(this.step2);
    this.hide(this.step1);
  }

  saveSettings() {
    this.cookieGroups.forEach((group) => {
      if (group.id && this.cookies.groupConsent[group.id] === true) {
        this.enableScriptGroup(group.id);
      } else if (group.id && this.cookies.groupConsent[group.id] === false) {
        this.disableScriptGroup(group.id);
        group.cookies.forEach((cookie) => this.unsetCookie(cookie.title));
      }
    });

    this.hide(this.step2);
    this.hide(this.consent);
    enableBodyScroll(this.consentMain);
    this.cookies.showOnInit = false;
    this.setCookie('CookieConsent', JSON.stringify(this.cookies), 365);

    // window.location.reload();
  }

  /*
   * Templating
   */

  createMainTemplate(content) {
    const closeButtonHtml = `
      <button
        class="rs-cc__close button"
        aria-label="${this.texts.closeButtonTitle}"
        title="${this.texts.closeButtonTitle}">
        <svg class="icon icon--close" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <title>${this.texts.closeButtonTitle}</title>
        <path d="M17.509 16l14.179 14.179c0.417 0.417 0.417 1.092 0 1.509s-1.092 0.417-1.509 0l-14.179-14.179-14.179 14.179c-0.417 0.417-1.092 0.417-1.508 0s-0.417-1.092 0-1.509l14.179-14.179-14.179-14.179c-0.417-0.417-0.417-1.092 0-1.508s1.092-0.417 1.508 0l14.179 14.179 14.179-14.179c0.417-0.417 1.092-0.417 1.509 0s0.417 1.092 0 1.508l-14.179 14.179z"></path>
        </svg>
      </button>
    `;

    const cookieConsentHtml = `
    <div class="rs-cc--overlay">
      <div class="rs-cc--main">
        <div class="rs-cc__step-1 rs-cc__step rs-cc--hide">
            <div class="container rs-cc__step-container">
              <div class="rs-cc__step-description">
                ${content.step1.description}
                <button class="rs-cc__open-settings button button--rs-cc -underlined">${this.texts.openSettings}</button>
              </div>
              <div class="rs-cc__buttons-container">
                <button class="rs-cc__confirm-all button button--rs-cc">${this.texts.confirmAll}</button>
              </div>
              ${closeButtonHtml}
            </div>
          </div>
          <div class="rs-cc__step-2 rs-cc__step rs-cc--hide">
            <div class="rs-cc__step-container -step-2">
              <div>
                <p class="rs-cc__step-title">${content.step2.title}</p>
                <div>${content.step2.description}</div>
                <div class="rs-cc__cookie-groups">
                </div>
                <div class="rs-cc__buttons-container">
                  <button class="rs-cc__save-settings button button--rs-cc">${this.texts.saveSettings}</button>
                </div>
                ${closeButtonHtml}
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    const cookieConsentTemplate = document.createElement('div');
    cookieConsentTemplate.classList.add('rs-cc');
    cookieConsentTemplate.innerHTML = cookieConsentHtml;

    return cookieConsentTemplate;
  }

  createCookieGroup(group) {
    const cookieGropHtml = `
    <div class="rs-cc__group-text">
      <div class="rs-cc__text-left">
        <div class="form-item form-item--checkbox">
          <input type="checkbox" id="${group.id}" class="rs-cc__checkbox input-checkbox">
          <label for="${group.id}"></label>
        </div>
      </div>
      <div class="rs-cc__text-right">
        <p class="rs-cc__step-segment">${group.title} (${group.cookies.length})</p>
        <div class="rs-cc__group-description">${group.description}</div>

        <button class="button button--rs-cc button--link rs-cc__show-list -underlined ${group.id}">${this.texts.openTable}</button>

        <div class="rs-cc__table-container rs-cc--hide">
          <table class="rs-cc__group-table">
            <thead>
              <tr>
                <th>${this.texts.cookieName}</th>
                <th>${this.texts.cookieTime}</th>
                <th>${this.texts.cookiePublisher}</th>
                <th>${this.texts.cookieIntention}</th>
              </tr>
            </thead>
          </table>
        </div>

        <button class="button button--rs-cc button--link rs-cc__hide-list rs-cc--hide ${group.title}">${this.texts.hideTable}</button>
      </div>
    </div>
    `;

    const cookieGroupTemplate = document.createElement('div');
    cookieGroupTemplate.classList.add('group');
    cookieGroupTemplate.innerHTML = cookieGropHtml;

    return cookieGroupTemplate;
  }

  createCookieTable(cookie) {
    const cookieTableHtml = `
      <td>${cookie.title}</td>
      <td>${cookie.ttl}</td>
      <td>${cookie.publisher}</td>
      <td>${cookie.intention}</td>
    `;

    const cookieTableTemplate = document.createElement('tr');
    cookieTableTemplate.innerHTML = cookieTableHtml;

    return cookieTableTemplate;
  }

  /*
   * Helpers
   */

  enableScriptGroup(groupId) {
    const scripts = Array.from(document.querySelectorAll(`.rs-cc-${groupId}`));
    scripts.forEach((script) => this.enableScript(script));
  }

  disableScriptGroup(groupId) {
    const scripts = Array.from(document.querySelectorAll(`.rs-cc-${groupId}`));
    scripts.forEach((script) => this.disableScript(script));
  }

  enableScript(script) {
    script.removeAttribute('type');
    const parent = script.parentNode;
    parent.removeChild(script);
    const element = document.createElement('script');
    for (let i = 0; i < script.classList.length; i += 1) {
      element.classList.add(script.classList.item(i));
    }
    if (script.src) {
      const newSrc = script.src.split('&ts');
      const date = new Date();
      element.src = `${newSrc[0]}&ts=${date.getTime()}`;
    }
    const code = document.createTextNode(script.innerHTML);
    element.appendChild(code);
    const datum = new Date();
    element.id = datum.getTime();
    parent.appendChild(element);
  }

  disableScript(el) {
    const script = el;
    const parent = script.parentNode;
    parent.removeChild(script);
    script.type = 'text/plain';
    parent.insertBefore(script, parent.children[1]);
  }

  setCookie(name, value, ttl) {
    const datum = new Date();
    datum.setTime(datum.getTime() + ttl * 24 * 60 * 60 * 1000);
    const expires = `expires=${datum.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/;`;
  }

  unsetCookie(cname) {
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    const domain = window.location.hostname;
    const domainLevels = domain.split('.');
    let cookieDomain = `.${domainLevels[domainLevels.length - 1]}`;
    for (let i = domainLevels.length - 1; i > 0; i -= 1) {
      cookieDomain = `.${domainLevels[i - 1] + cookieDomain}`;
      document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookieDomain}`;
    }
  }

  getCookie(cName) {
    const name = `${cName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  show(el) {
    el.classList.remove('rs-cc--hide');
    el.classList.add('rs-cc--show');
  }

  hide(el) {
    el.classList.remove('rs-cc--show');
    el.classList.add('rs-cc--hide');
  }

  /*
   * Validation
   */

  validate(options) {
    if (!options) {
      this.error('No options parameter');
    } else if (!options.cookieGroups || !options.content) {
      this.error('No cookieGropus or content properties');
    } else {
      const gropus = options.cookieGroups;
      const cont = options.content;
      if (!gropus[0]) {
        this.error('Invalid cookieGroups array');
      } else if (
        !cont.step1 ||
        !cont.step1.title ||
        !cont.step1.description ||
        !cont.step2 ||
        !cont.step2.title ||
        !cont.step2.description
      ) {
        this.error('Invalid content object');
      } else {
        gropus.forEach((g) => {
          if (!g.title || !g.description || !g.cookies) {
            this.error('Invalid cookieGroups item');
          } else {
            g.cookies.forEach((c) => {
              if (!c.title || !c.ttl || !c.publisher || !c.intention) {
                this.error('Invalid cookie item');
              }
            });
          }
        });
      }
    }
    return options;
  }

  error(error) {
    throw new Error(`CookieConsent: ${error}`);
  }

  logCookies(title) {
    const logElement = document.querySelector('.cc-log');
    const cookies = document.cookie.split(';');

    const titleTemplate = document.createElement('h3');
    titleTemplate.innerHTML = title;
    logElement.appendChild(titleTemplate);

    const msgTemplate = document.createElement('div');
    cookies.forEach((el) => {
      const msgHtml = document.createElement('span');
      msgHtml.innerHTML = `${el}<br>`;
      msgTemplate.appendChild(msgHtml);
    });
    logElement.appendChild(msgTemplate);
  }
}

export default CookieConsent;
