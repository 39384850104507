/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
// https://www.npmjs.com/package/handorgel

import Handorgel from 'handorgel';

export default function accordionInit() {
  const accordions = document.querySelectorAll('.handorgel');

  let options = {
    initialOpenTransition: true,
    collapsible: true,
    multiSelectable: false,
  };

  accordions.forEach((accordion) => {
    const multiSelectable = accordion.getAttribute('data-multiselectable');
    if (multiSelectable) {
      options = { ...options, multiSelectable: true };
    }

    const notCollapsible = accordion.getAttribute('data-not-collapsible');
    if (notCollapsible) {
      options = { ...options, collapsible: false };
    }

    const singleAaccordion = new Handorgel(accordion, options);
  });
}
